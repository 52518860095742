import * as React from "react";

export default function RnFullLogo() {
  return (
    <img
      alt="Logo Route Nazionale 2024"
      src="Logo_full.png"
      width={230}
      height={73}
    />
  );
}
